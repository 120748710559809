import React from 'react'
import styled from '@emotion/styled'
import { colors } from "../utils/presets"
import hex2rgba from "hex2rgba"
import { formInput } from "../utils/form-styles"
import { buttonStyles } from "../utils/styles"

const Submit = styled.div`
  ${buttonStyles.default};
  margin-top: 20px;
`

const SingleLineInput = styled(`input`)`
  ${formInput};
  width: 100%;
  :focus {
    border-color: ${colors.gatsby};
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${hex2rgba(colors.lilac, 0.25)};
  }
`

const Label = styled(`label`)`
  :after {
    content: ${props => (props.isRequired ? `'*'` : ``)};
    color: ${colors.warning};
  }
`

export default class IndexPage extends React.Component {
  state = {
    submit: false,
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault();
    const data = new FormData(event.target);
    fetch('https://maxongzb.activehosted.com/proc.php', {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    })
    .then(response => {
      this.setState({ submit: true });

      setTimeout(() => {
          this.setState({ submit: false });
        }, 2000);

      if (this.state.submit !== true) {
        alert('Email Subscription Was Unsuccessful!!!')
      }
      else{
        alert('Welcome to the Tribe!!! Please Check Your Email to ' +
        'Confirm Your Subscription!!!');
      }

    })
    .catch(err => {
      console.log('err', err);
      alert(err);
    });
  }

  render() {
    return (
      <div>
        <Label>Subscribe to  a weekly newsletter,
          fresh from the oven at one byte at a time</Label><br/><br/>

        <div>
          <form onSubmit={this._handleSubmit}>
            <input type="hidden" name="u" value="1" />
            <input type="hidden" name="f" value="1" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <SingleLineInput type="email" onChange={this._handleChange}
                             placeholder="Johnwick@email.com" name="email"/>

            <br/><br/>

            <SingleLineInput type="fullname" onChange={this._handleChange}
                             placeholder="John Wick" name="fullname"/>
            <Submit><input type="submit"/></Submit>
          </form>
        </div>
      </div>
    )
  }
}
